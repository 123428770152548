// extracted by mini-css-extract-plugin
export var ProjectListContainer = "Portfolio-module--ProjectListContainer--19EgR";
export var head = "Portfolio-module--head--2NL2N";
export var Left = "Portfolio-module--Left--13bPn";
export var Right = "Portfolio-module--Right--vwVyl";
export var ItemClassName = "Portfolio-module--ItemClassName--30j5X";
export var ScrollContainerClassName = "Portfolio-module--ScrollContainerClassName--3vuuk";
export var WrapperClassName = "Portfolio-module--WrapperClassName--3kgMz";
export var TitleContainer = "Portfolio-module--TitleContainer--3awCK";
export var PhonePreview = "Portfolio-module--PhonePreview--1sxrM";
export var NoPhonePreview = "Portfolio-module--NoPhonePreview--2trbL";
export var NoPhonePreviewParaContent = "Portfolio-module--NoPhonePreviewParaContent--3PtSz";
export var ParaContainer = "Portfolio-module--ParaContainer--2PfQO";
export var left = "Portfolio-module--left--21Nqq";
export var right = "Portfolio-module--right--1K9MC";
export var Title = "Portfolio-module--Title--2w3Ny";
export var MobileTitle = "Portfolio-module--MobileTitle--Wr46F";
export var Project = "Portfolio-module--Project--2MX8Q";
export var Container = "Portfolio-module--Container--XwW_N";
export var ProjectContent = "Portfolio-module--ProjectContent--GTqvF";
export var NoPhoneProject = "Portfolio-module--NoPhoneProject--odMqy";
export var MobileProjectContent = "Portfolio-module--MobileProjectContent--c7U_J";
export var ProjectTitle = "Portfolio-module--ProjectTitle--1ugu7";
export var ChangChuayTitleText = "Portfolio-module--ChangChuayTitleText--15kwi";
export var Preview = "Portfolio-module--Preview--3egFZ";
export var Background = "Portfolio-module--Background--2foJN";
export var BackgroundBottom = "Portfolio-module--BackgroundBottom--2J7VP";
export var Link = "Portfolio-module--Link--2RQ4p";
export var ArrowMove = "Portfolio-module--ArrowMove--3Ruqw";
export var ProjectParaContainer = "Portfolio-module--ProjectParaContainer--2_2iT";
export var ProjectCounter = "Portfolio-module--ProjectCounter--IgqCK";
export var SelectedCounter = "Portfolio-module--SelectedCounter--23bd2";