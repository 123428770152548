import * as classes from './Portfolio.module.css'
import React, {useEffect, useState, useRef} from "react";
import "./hideScrollbar.css";
import line from './../../assets/images/others/line.png'
import doubleArrow from './../../assets/images/others/Group1button.png'
import arrow from './../../assets/images/others/right-arrow-pink.png'
import Header from '../../components/Header/Header.js';
import content from "../../../content.json"
import { ProjectsData } from '../../components/PortfolioSection/ProjectsData';

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import Content from '../../../content.json';


const Portfolio = (props) => {

  const isBrowser = () => typeof window !== "undefined";
  let url = "";
  let project_id = 0;
  if(isBrowser()){
    url = new URL(window.location.href);
    project_id = url.searchParams.get("id");
  }

  const [ind, setInd] = useState(project_id);
  const [visibleItem, setVisibleItem] = useState(-1);

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);

    return (
      isFirstItemVisible ? null :
      <div id="LeftArrow" className={classes.Left} onClick={() => scrollPrev()}>
        <img src={doubleArrow} />
      </div>
    );
  }

  const apiRef = useRef();

  const scrollToSection = (id) => {
    apiRef.current.scrollToItem(document.getElementById('key'+id))
  }

  useEffect(() => {
    if(apiRef.current && apiRef.current.scrollToItem)
      setTimeout(() => {
        apiRef.current.scrollToItem(document.getElementById('key'+ind))
      }, 2000);
  },[])

  var PIXEL_STEP  = 10;
  var LINE_HEIGHT = 40;
  var PAGE_HEIGHT = 800;
  function onWheel(ev){
    const scrollContainer = document.getElementById("ProjectList");
    scrollContainer.addEventListener("wheel", ev =>{
      var sX = 0, sY = 0,       // spinX, spinY
      pX = 0, pY = 0;       // pixelX, pixelY
      
  // Legacy
  if ('detail'      in ev) { sY = ev.detail; }
  if ('wheelDelta'  in ev) { sY = -ev.wheelDelta / 120; }
  if ('wheelDeltaY' in ev) { sY = -ev.wheelDeltaY / 120; }
  if ('wheelDeltaX' in ev) { sX = -ev.wheelDeltaX / 120; }

  // side scrolling on FF with DOMMouseScroll
  if ( 'axis' in ev&& ev.axis === ev.HORIZONTAL_AXIS ) {
    sX = sY;
    sY = 0;
  }

  pX = sX * PIXEL_STEP;
  pY = sY * PIXEL_STEP;

  if ('deltaY' in ev) { pY = ev.deltaY; }
  if ('deltaX' in ev) { pX = ev.deltaX; }

  if ((pX || pY) && ev.deltaMode) {
    if (ev.deltaMode == 1) {          // delta in LINE units
      pX *= LINE_HEIGHT;
      pY *= LINE_HEIGHT;
    } else {                             // delta in PAGE units
      pX *= PAGE_HEIGHT;
      pY *= PAGE_HEIGHT;
    }
  }

  // Fall-back if spin cannot be determined
  if (pX && !sX) { sX = (pX < 1) ? -1 : 1; }
  if (pY && !sY) { sY = (pY < 1) ? -1 : 1; }
  if (sY > 0) {
    document.getElementById('RightArrow').click()
    //scrollContainer.scrollLeft += ev.deltaY;
  } else if (sY < 0) {
    document.getElementById('LeftArrow').click()
    //scrollContainer.scrollLeft += ev.deltaY;
  }
  return { spinX  : sX,
           spinY  : sY,
           pixelX : pX,
           pixelY : pY };
});}
      
    // const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  function Footer() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
      <div
        className={classes.ParaContainer}
        disabled={isLastItemVisible} onClick={() => scrollNext()}>
        <img src={doubleArrow} />
      </div>
    );
  }
  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    const visibility = React.useContext(VisibilityContext);
    if(visibility.visibleItemsWithoutSeparators[0]){
      setVisibleItem(
        visibility.visibleItemsWithoutSeparators[0].charAt(visibility.visibleItemsWithoutSeparators[0].length-1)
      )
    }
    return (
      isLastItemVisible ? null :
      <div id="RightArrow" className={classes.Right} onClick={() => {scrollNext()}}>
        <img src={doubleArrow} />
      </div>
    );
  }  

  return (
    <>
      <Header
      logoStyle={{
        marginLeft: "30px"
      }}
      menuContainerStyle={{
      }}
      />
      <div className={classes.ProjectListContainer} id="ProjectList">
        <div className={[classes.Title, classes.MobileTitle].join(' ')}>
          <div className={classes.TitleContainer}>
            <p><span>{Content.projectsPage.landingPage.heading.split(' ')[0]}</span> {Content.projectsPage.landingPage.heading.split(' ').slice(1).join(' ')}</p>
          </div>
          <div className={classes.ParaContainer}>
            <span>{Content.projectsPage.landingPage.content}</span>
          </div>
        </div>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          apiRef={apiRef}
          itemClassName={classes.ItemClassName}
          separatorClassName={classes.SeparatorClassName}
          scrollContainerClassName={classes.ScrollContainerClassName}
          wrapperClassName={classes.WrapperClassName}
          transitionDuration={500}
          transitionBehavior={"smooth"}
        >
          <div className={classes.Title}>
            <div className={classes.TitleContainer}>
              <p><span>{Content.projectsPage.landingPage.heading.split(' ')[0]}</span> {Content.projectsPage.landingPage.heading.split(' ').slice(1).join(' ')}</p>
            </div>
            <div className={classes.ParaContainer}>
              <span>{Content.projectsPage.landingPage.content}</span>
            </div>
          </div>
          {
              ProjectsData.map((data,index)=>{
                return(
                  <div className={[classes.Project, classes.Container].join(' ')} key={index} id={`key${index}`}>
                    <div className={data.phone_logo===""?[classes.Project, classes.NoPhoneProject].join(' ') : classes.Project}>
                    <div className={classes.Background}>
                      <img src={data.diamond} alt="" />
                    </div>
                    <div className={data.phone_logo===""?classes.NoPhonePreview:classes.Preview}>
                      <img src={data.display_image} />
                    </div>
                    {
                      data.phone_logo!==""?<div className={classes.PhonePreview}>
                      <img src={data.phone_logo} />
                    </div>:null
                    }
                    <div className={classes.BackgroundBottom}>
                      <img src={data.diamond} alt="" />
                    </div>
                    <div className={classes.ProjectContent}>
                      <div className={classes.ProjectTitle}>
                        <img src={data.logo} alt="" />
                      </div>
                      <div className={data.phone_logo===""?classes.NoPhonePreviewParaContent:classes.ProjectParaContainer}>
                        {data.description}
                      </div>
                      { data.website === "" ? null : <div
                        className={classes.Link}>
                        <img src={line} alt="link" />
                        <a href={data.website} target="_blank" >VISIT </a>
                        <img className={classes.ArrowMove} src={arrow} alt="" />
                      </div> }
                    </div>
                    </div>
                    <div className={[classes.ProjectContent, classes.MobileProjectContent].join(' ')}>
                      <div className={classes.ProjectTitle}>
                        <img src={data.logo} alt="" />
                      </div>
                      <div className={data.phone_logo===""?classes.NoPhonePreviewParaContent:classes.ProjectParaContainer}>
                        {data.description}
                      </div>
                      <div
                        className={classes.Link}>
                        <img src={line} alt="link" />
                        <a href={data.website} target="_blank" >VISIT </a>
                        <img className={classes.ArrowMove} src={arrow} alt="" />
                      </div>
                    </div>
                  </div>
                );
                
              })
          }
        </ScrollMenu>
        <div className={classes.ProjectCounter}>
          {ProjectsData.map(function(project, index){
            return <div className={visibleItem == index ? classes.SelectedCounter : null } onClick={()=>scrollToSection(index)}>
              <div></div>
            </div>
          })}
        </div>
      </div>
    </>
  )
}

export default Portfolio